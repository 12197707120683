@keyframes Appearing {
  from {
    stroke-dashoffset: 1100;
  }
  to {
    stroke-dashoffset: 0;
  }
}

.PaperClipAnimation {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
  animation-delay: 0.3s;
  animation-duration: 1.2s;
  animation: Appearing 1.5s ease-in-out alternate;
}
