/* Define the keyframes for the animation */
@keyframes fadeAndMove {
  0% {
    opacity: 0; /* Start with opacity 0 (fully transparent) */
    transform: translateY(15px); /* Start 15px down from the final position */
  }
  100% {
    opacity: 1; /* End with opacity 1 (fully opaque) */
    transform: translateY(0); /* Move to the final position (0px from bottom) */
  }
}

/* Apply the animation to your element */
.FadeInAndMov {
  animation: fadeAndMove 1s ease-in-out; /* 1s duration with ease-in-out timing function */
}
